export const LEADING_SPACE_CLASS = " xl:leading-8 ";
export const PC_HIDDEN_CLASS = " xl:hidden ";
export const PC_SHOW_CLASS = " hidden xl:block ";
export const BG_WHITE_BLOCK = " bg-white p-5 xl:py-8 xl:px-5";
export const UP_AND_DOWN_CENTER = " flex items-center ";
export const LINE_SPACING_PB_CLASS = " mb-5 lg:mb-10 ";
export const P_CLASS = " p-5 xs:p-7 sm:p-10 md:p-10 lg:p-12 xl:p-16 2xl:p-20 3xl:p-24 ";
export const PB_CLASS = " pb-2 xs:pb-7 sm:pb-10 md:pb-10 lg:pb-12 xl:pb-16 2xl:pb-20 3xl:pb-24 ";


// セクション
export const FLEX_CLASS = LEADING_SPACE_CLASS + "flex flex-wrap relative ";
export const SECTION_AREA_CLASS = FLEX_CLASS + "flex-row ";
export const SECTION_AREA_REVERSE_CLASS = FLEX_CLASS + "flex-col-reverse xl:flex-row ";
export const SECTION_P_AREA_CLASS = SECTION_AREA_CLASS + P_CLASS;
export const SECTION_PB_AREA_CLASS = SECTION_AREA_CLASS + PB_CLASS;

// 見出し1
export const H1_CLASS = "text-xl xs:text-3xl sm:text-6xl 3xl:text-5xl mb-8 xl:mb-16 ";
// export const H1_CLASS = "text-3xl  sm:text-6xl 3xl:text-6xl mb-8 xl:mb-16 ";
// export const H2_CLASS = "text-2xl  sm:text-3xl 3xl:text-4xl mb-8 xl:mb-16 sm:tracking-wider 3xl:tracking-widestEx sm:leading-13 font-medium xl:font-light ";
export const H2_CLASS = "text-lg xs:text-xl sm:text-3xl 3xl:text-5xl mb-4 sm:mb-8 xl:mb-16 sm:tracking-wider 3xl:tracking-normal sm:leading-13 font-medium xl:font-light ";
export const H3_CLASS = "text-xl sm:text-2xl 3xl:text-3xl ";
export const H4_CLASS = "text-lg sm:text-xl 3xl:text-2xl "
export const H5_CLASS = "text-base xs:text-lg sm:text-2xl 3xl:text-xl font-normal ";
export const H6_CLASS = "text-base font-semibold xl:font-medium ";


// タイトル
export const KEY_VISUAL_OFFSET_TITLE_AREA_CLASS
    = "w-full z-30 "
    + "px-5 sm:px-10 py-5 sm:py-8 lg:p-8 xl:pl-20 3xl:pl-24 tracking-normal ";

export const KEY_VISUAL_TITLE_EN_CLASS
    = "text-4xl xl:text-xl font-medium tracking-tighter inline-block>";

export const KEY_VISUAL_TITLE_JP_CLASS
    = "text-md xl:text-sm ml-3 sm:ml-10 xl:ml-5 inline-block";


// キービジュアル > エリア
export const KEY_VISUAL_MESSAGE_AREA_COMMON_CLASS
    = UP_AND_DOWN_CENTER
    + "p-5 xs:p-7 sm:p-10 xl:p-10 "
    + "py-10 xl:py-16 2xl:py-20 3xl:py-32 "
    + "text-white text-left my-auto text-base "


export const TOP_KEY_VISUAL_OFFSET_MESSAGE_AREA_LEFT_CLASS
    = KEY_VISUAL_MESSAGE_AREA_COMMON_CLASS
    + "-mt-32 sm:-mt-112 md:-mt-112 lg:-mt-144 xl:mt-10 2xl:mt-40 3xl:mt-36 "
    + "lg:ml-12 xl:ml-16 2xl:ml-16 3xl:ml-24 "
    + "w-11/12 sm:w-10/12 md:w-9/12 lg:w-8/12 xl:w-4/12 "
    + "bg-indigo z-30 ";

export const TOP_KEY_VISUAL_RIGHT_IMG_AREA_CLASS
    = "w-full xl:w-8/12 "
    + "xl:-ml-16 2xl:-ml-16 3xl:-ml-24 "
    + "pl-14 sm:pl-28 xl:pl-0 ";

export const KEY_VISUAL_OFFSET_MESSAGE_AREA_CLASS
    = KEY_VISUAL_MESSAGE_AREA_COMMON_CLASS
    + "w-11/12 sm:w-11/12 md:w-8/12 lg:w-7/12 xl:w-4/12 2xl:w-4/12  "
    // + "text-md xl:text-base bg-indigo z-30 ";
    + "text-base sm:text-md bg-indigo z-30 ";

export const KEY_VISUAL_OFFSET_MESSAGE_AREA_RIGHT_OFFSET_CLASS
    = "w-1/12 sm:w-1/12 md:w-4/12 lg:w-5/12  "
    + "2xl:hidden ";

export const KEY_VISUAL_OFFSET_MESSAGE_AREA_LEFT_CLASS
    = KEY_VISUAL_OFFSET_MESSAGE_AREA_CLASS
    + "sm:py-16 xl:ml-20 xl:-mr-20 xl:mt-28 "
    + "-mt-20 sm:-mt-36 md:-mt-32 xl:mt-32 2xl:mt-32 ";

export const KEY_VISUAL_RIGHT_IMG_AREA_CLASS
    = "w-full xl:w-8/12 2xl:w-8/12 "
    + "pl-10 sm:pl-20 xl:pl-0 z-10 ";

export const KEY_VISUAL_OFFSET_MESSAGE_AREA_RIGHT_CLASS
    = KEY_VISUAL_OFFSET_MESSAGE_AREA_CLASS
    + "xl:w-6/12 "
    + "-mt-32 sm:-mt-52 md:-mt-64 lg:-mt-96 xl:-mt-96 2xl:mt-0 "
    + "sm:py-14 xl:-ml-5 ";

export const KEY_VISUAL_LEFT_IMG_AREA_CLASS
    = "w-full xl:w-8/12 2xl:w-8/12 "
    + "pr-10 sm:pr-28 xl:pr-0 xl:-mr-20 xl:-mt-28 ";

export const KEY_VISUAL_RIGHT_HALF_IMG_AREA_CLASS
    = "w-full xl:w-7/12 z-20 ";

export const KEY_VISUAL_MESSAGE_AREA_CLASS
    = KEY_VISUAL_MESSAGE_AREA_COMMON_CLASS
    + P_CLASS + " text-base sm:text-md xl:text-base "
    + "sm:leading-11 3xl:leading-9 "
    + "tracking-wider font-light ";

export const KEY_VISUAL_RIGHT_HALF_MESSAGE_AREA_CLASS
    = LEADING_SPACE_CLASS
    + UP_AND_DOWN_CENTER
    + P_CLASS + "relative ";

// キービジュアル > イメージ
export const KEY_VISUAL_VARIABLE_IMG_CLASS
    = "w-full z-20 ";
// = "w-full h-112 sm:h-auto object-cover sm:object-none z-20 ";

// キービジュアル > タイトル
export const KEY_VISUAL_TITLE_MESSAGE_AREA_CLASS
    = "w-full xl:w-5/12 bg-gray text-white z-10 text-center h-1/6 ";

export const KEY_VISUAL_TITLE_AREA_CLASS
    = KEY_VISUAL_OFFSET_TITLE_AREA_CLASS
    + "bg-gradient-to-r from-white-title-area text-left ";


// コンテンツ
export const CONTENTS_AREA_CLASS
    = "w-full px-5 xs:p-7 sm:px-10 pb-5 sm:pb-10 z-40 xl:z-30 ";

export const CONTENTS_LEFT_IMG_AREA_CLASS
    = CONTENTS_AREA_CLASS
    + "xl:w-5/12 xl:p-0 ";

export const CONTENTS_RIGHT_IMG_AREA_CLASS
    = CONTENTS_LEFT_IMG_AREA_CLASS;

export const CONTENTS_LEFT_MESSAGE_AREA_CLASS
    = CONTENTS_AREA_CLASS
    + UP_AND_DOWN_CENTER
    + P_CLASS + "xl:w-7/12 ";

export const CONTENTS_RIGHT_MESSAGE_AREA_CLASS
    = CONTENTS_LEFT_MESSAGE_AREA_CLASS;

export const CONTENTS_HALF_AREA_CLASS
    = CONTENTS_AREA_CLASS
    + P_CLASS + "xl:w-6/12 py-10 ";


// テーブル
export const TABLE_ROW_CLASS
    = SECTION_AREA_CLASS
    + "w-full border-b-2 border-gray-300 border-dashed z-10 ";

export const TABLE_TITLE_CLASS
    = "w-full xl:w-3/12 3xl:w-2/12 pt-6 md:pt-4 xl:py-8 font-semibold tracking-normal";

export const TABLE_TEXT_CLASS
    = "w-full xl:w-9/12 3xl:w-10/12 pb-6 md:pb-4 xl:py-8 2xl:pl-5";

// 背景模様
export const TOP_BACK_GROUND_PATTERN_CLASS
    // = "w-full xl:w-11/12 2xl:w-11/12 "
    // + "2xl:-mt-20 3xl:-mt-24 "
    // + "absolute z-0 top-0 right-0 "
    // + "h-screen sm:h-auto object-cover sm:object-none ";
    = "w-full xl:w-11/12 2xl:w-11/12 "
    + "2xl:-mt-20 3xl:-mt-24 "
    + "absolute z-0 top-0 right-0 ";

export const ABOUT_KV_BACK_GROUND_PATTERN_CLASS
    = "absolute w-full xl:w-9/12 3xl:w-8/12 z-0 -top-1 left-0 ";

export const ABOUT_COMPANY_BACK_GROUND_PATTERN_CLASS
    = "absolute w-7/12 md:w-4/12 xl:w-7/12 z-0 top-80 xl:top-0 right-0 ";

export const ABOUT_HISTORY_BACK_GROUND_PATTERN_CLASS
    = "absolute w-full xl:w-7/12 z-0 bottom-0 right-0 ";

export const BUSINESS_BACK_GROUND_PATTERN_CLASS
    = "w-full lg:w-11/12 xl:w-9/12 2xl:w-7/12 3xl:w-6/12 "
    + "top-0 sm:top-28 xl:-top-32 2xl:-top-20 3xl:-top-1 "
    + "absolute right-0 z-10 ";

export const SECURITYSERVICE_BACK_GROUND_PATTERN_CLASS
    = "w-full lg:w-11/12 xl:w-9/12 2xl:w-7/12 3xl:w-6/12 "
    + "top-0 sm:top-28 xl:-top-32 2xl:-top-20 3xl:-top-1 "
    + "absolute right-0 z-10 ";    

export const CLUB_BACK_GROUND_PATTERN_CLASS
    = "w-full md:w-11/12 lg:w-11/12 xl:w-8/12 2xl:w-7/12 "
    + "sm:top-28 lg:top-28 xl:-top-24 2xl:-top-12 3xl:-top-20 "
    + "absolute right-0 z-0 ";

export const EMPLOYMENT_BACK_GROUND_PATTERN_CLASS
    = "w-full "
    + "top-0 sm:top-28 xl:-top-1 right-0 "
    + "absolute z-0 ";

// 背景テキスト
export const ABOUT_BACK_GROUND_TEXT_CLASS
    = "w-4/12 xs:w-3/12 sm:w-3/12  xl:w-full "
    + "-top-16  xl:bottom-0 "
    + "left-0 xl:right-0 "
    + "absolute z-0 ";

export const EVENT_BACK_GROUND_TEXT_H_CLASS
    = "w-8/12 "
    + "-bottom-20 2xl:-bottom-40 3xl:-bottom-32 3xl:-ml-4 left-0 "
    + "absolute z-0";

export const EVENT_BACK_GROUND_TEXT_V_CLASS
    = "w-3/12 "
    + "-top-10 right-0 "
    + "absolute z-0";


export const EMPLOYMENT_BACK_GROUND_TEXT_CLASS
    = "w-6/12 sm:w-5/12 lg:w-4/12 xl:w-3/12 3xl:w-3/12 "
    + "top-0  md:-top-144 lg:-top-96 xl:-top-56 2xl:-top-56 3xl:-top-32 "
    + "left-0 xl:left-24 "
    + "absolute z-30 xl:z-40 ";

export const TRAINIG_BACK_GROUND_TEXT_CLASS
    = "w-5/12 sm:w-4/12 xl:w-5/12  "
    + "top-20 lg:top-10 xl:-top-48 "
    + "-left-16 lg:-left-24 xl:-left-10 3xl:-left-8 "
    + "absolute z-20 ";

// 背景模様
export const INQUIRY_BACK_GROUND_PATTERN_CLASS
    = "w-full absolute top-112 xl:top-48 right-0 z-0 ";

// 画像のグレー背景を使用している為、現在使用している箇所はない
export const KV_BACK_GROUND_GLAY_AREA_CLASS
    = "bg-gray-light w-full sm:w-9/12 md:w-8/12 lg:w-7/12 xl:w-1/2 "
    + "py-96 sm:py-80 md:py-96 lg:py-112 xl:py-112 2xl:py-128 "
    + "absolute right-0 top-36 xl:top-0 -mt-1 z-0 ";

