import PropTypes from "prop-types";
import React from "react";
import "../css/style.css";
import Header from "./header";
import Footer from "./footer";

function Layout({ children, addClassName = "" }) {

  const style = { "fontFamily": 'Noto Sans JP, sansSerif' ,"fontSize": '18px '};


  return (
    <div className="my-0 mx-auto flex flex-col min-h-screen text-gray-900 tracking-wider overflow-x-hidden" style={style}>
      <Header />

      <main className={addClassName + " leading-loose font-light tracking-wider flex-1 w-full mx-auto mt-14 xl:mt-20"}>
        {/* <main className={addClassName + " leading-loose text-sm font-light tracking-wider flex-1 w-full mx-auto xl:mt-20"}> */}
        <article>
          {children}
        </article>
      </main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  addClassName: PropTypes.string,
};

export default Layout;
