import React from "react";
// import { Link } from "gatsby";
import * as CONST from "../components/const";

function Footer() {
  return (
    <footer className="relative bg-black py-10 xl:py-6 text-xs sm:text-sm xl:text-sm text-white justify-center z-20">
      {/* <ul className="pb-4 grid grid-cols-1 xs:grid-cols-2 gap-y-6">
        <li className="text-left xs:text-right">
          <ul className="space-y-6">
            <li>
              <Link
                className="mx-5 xs:mx-2 sm:mx-5"
                key="ISMSへの取り組み"
                to="/isms"
              >ISMSへの取り組み</Link>
            </li>
            <li>
              <Link
                className="mx-5 xs:mx-2 sm:mx-5"
                key="お問い合わせ"
                to="/inquiry"
              >お問い合わせ</Link>
            </li>
          </ul>
        </li>
        <li className="">
          <ul className="space-y-6">
            <li>
              <Link
                className="mx-5 xs:mx-2 sm:mx-5"
                key="セキュリティーアクション"
                to="/isms#security"
              >セキュリティーアクション</Link>
            </li>
            <li>
              <Link
                className="mx-5 xs:mx-2 sm:mx-5"
                key="プライバシー"
                to="/privacypolicy"
              >プライバシーポリシー</Link>
            </li>
          </ul>
        </li>
      </ul>
      <p className="flex justify-center hidden xs:flex top-20 sm:mt-1 xl:-mt-3 left-2/4 absolute">
        <span>|</span>
      </p> */}
      <p className="flex justify-center mt-6 xs:mt-0">
        <span className={CONST.PC_SHOW_CLASS}>Copyright </span>
        (C) HORIZON
        <span className={CONST.PC_SHOW_CLASS}>. All Rights Reserved.</span>
      </p>
    </footer>
  );
}

export default Footer;
